import Link from "next/link";
import Styles from "@/styles/Footer.module.css";

const FooterDynamic = ({ dataFooter }) => {
  return (
    <>
      <div className="col-lg-3 text-sm-center">
        <div className={`${Styles.widget} ${Styles.widget_nav_menu}   mb-5`}>
          <h4 className={`${Styles.widget__title} text-sm-center`}>
            খবর বিভাগ
          </h4>
          <ul>
            <li>
              <Link href={"/news/"} title="সবশেষ" prefetch={false}>
                সবশেষ
              </Link>
            </li>
            {dataFooter &&
              dataFooter.map((item, index) =>
                index < 5 ? (
                  <li key={item.slug + index}>
                    <Link
                      href={"/news/category/" + item.slug}
                      title={item.name}
                      prefetch={false}
                    >
                      {item.name}
                    </Link>
                  </li>
                ) : (
                  ""
                )
              )}
          </ul>
        </div>
      </div>

      <div className="col-lg-3 text-sm-center">
        <div
          className={`${Styles.widget} ${Styles.widget_nav_menu} text-sm-center  mb-5`}
        >
          <h4 className={`${Styles.widget__title} text-sm-center`}>
            আরও খবর বিভাগ
          </h4>
          <ul>
            {dataFooter &&
              dataFooter.map((item, index) =>
                index > 4 ? (
                  <li key={item.slug + index}>
                    <Link
                      href={"/news/category/" + item.slug}
                      title={item.name}
                      prefetch={false}
                    >
                      {item.name}
                    </Link>
                  </li>
                ) : (
                  ""
                )
              )}
            <li>
              <Link href={"/opinion"} title="মতামত" prefetch={false}>
                মতামত
              </Link>
            </li>
            <li>
              <Link href={"/advertisement"} title="বিজ্ঞাপন" prefetch={false}>
                বিজ্ঞাপন
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default FooterDynamic;
